import React, { useCallback, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormInputText } from "../../components/form-components/FormInputText";
import { CidrAddressModel } from "../../queries/cidr-address/Models";

export type EditCidrAddressProperties = {
  open: boolean;
  srcModel: CidrAddressModel | null;
  onClose: () => void;
  saveCidrAddress: (cidrAddress: CidrAddressModel) => void;
};

type CidrAddressFormInput = {
  id: number;
  address: string;
};

export default function EditCidrAddress({ open, srcModel, onClose, saveCidrAddress }: EditCidrAddressProperties) {
  const { control, handleSubmit, reset, setValue } = useForm<CidrAddressFormInput>({
    defaultValues: { id: srcModel?.id ?? 0, address: srcModel?.address ?? "" },
  });

  useEffect(() => {
    //reset the form each time it is opened
    if (true === open) {
      reset();
      setValue("address", srcModel?.address ?? "");
      setValue("id", srcModel?.id ?? 0);
    }
  }, [open]);

  const onClick = useCallback((data: CidrAddressFormInput) => {
    //create the new/updated item and send it to the save function
    const newCidrAddress = {
      id: data.id,
      address: data.address,
    } as CidrAddressModel;

    saveCidrAddress(newCidrAddress);
    onClose();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>{`${srcModel ? "Edit" : "Add"} Address`}</DialogTitle>
      <DialogContent dividers={true}>
        <FormInputText
          name="address"
          label="CIDR Address"
          control={control}
          rules={{
            required: { value: true, message: "Address is required" },
            minLength: { value: 9, message: "Invalid CIDR address - must contain at least 9 characters" },
            maxLength: { value: 18, message: "Invalid CIDR address - cannot contain more than 18 characters" },
            pattern: {
              value: /^([0-2]{0,1}[0-9]{1,2}\.){3}[0-2]{0,1}[0-9]{1,2}\/(?:[0-9]|[1-2][0-9]|3[0-2])$/,
              message: "Invalid CIDR address",
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit(onClick)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
