import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../../contexts/app-context/AppContext";
import { Box } from "@mui/system";
import { CopyDeviceJobActionTypeEnum } from "../CopyDeviceJobActions";
import {
  BatteryDeviceLink,
  BatteryPadLink,
  BatteryTankLink,
  DeviceCopyDevice,
  SingleObjectCopy,
  SingleObjectCopyStatusEnum,
  WellDeviceLink,
} from "../../../queries/copy-device/Models";
import { useDeviceCopyDeviceList } from "../../../queries/copy-device/UseDeviceCopyDeviceList";
import { CheckboxSelectionCallbackParams, ColDef, RowDataUpdatedEvent } from "ag-grid-community";
import ObjectEnabledCellRenderer, {
  ObjectEnabledRendererProps,
} from "../../../components/ag-grid-extensions/renderers/ObjectEnabledCellRenderer";
import ObjectVisibleCellRenderer, {
  ObjectVisibleRendererProps,
} from "../../../components/ag-grid-extensions/renderers/ObjectVisibleCellRenderer";
import { ConfigureJobStepProps } from "./ConfigureCopyJob";
import SideSheet from "../../../components/side-sheet/side-sheet/SideSheet";
import CopyOptions from "./CopyOptions";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import { innerJoin, leftOuterJoin } from "../../../utilities/Joins";
import { distinctBy } from "../../../utilities/DistinctBy";

export default function SelectDevicesStep({
  dialogOpen,
  job,
  dispatchJob,
  setNextButtonDisabled,
}: ConfigureJobStepProps) {
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const [deviceList, setDeviceList] = useState<Array<DeviceCopyDevice>>([]);
  const [wellLinks, setWellLinks] = useState<Array<WellDeviceLink>>([]);
  const [batteryDevices, setBatteryDevices] = useState<Array<BatteryDeviceLink>>([]);
  const [batteryTanks, setBatteryTanks] = useState<Array<BatteryTankLink>>([]);
  const [padBatteries, setPadBatteries] = useState<Array<BatteryPadLink>>([]);
  const { data: newDeviceInfo, isSuccess } = useDeviceCopyDeviceList({
    enabled: dialogOpen && (job?.srcCustID ?? 0) > 0 && (job?.srcOrgID ?? 0) > 0,
    job: job,
  });
  const [showHiddenDisabled, setShowHiddenDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (newDeviceInfo) {
      if (showHiddenDisabled) {
        setDeviceList(newDeviceInfo.objects);
      } else {
        setDeviceList(newDeviceInfo.objects.filter((o) => true === o.obj_enabled && true === o.obj_visible));
      }
      setWellLinks(newDeviceInfo.well_links);
      setBatteryDevices(newDeviceInfo.battery_devices);
      setBatteryTanks(newDeviceInfo.battery_tanks);
      setPadBatteries(newDeviceInfo.pad_batteries);
    }
  }, [dialogOpen, isSuccess, newDeviceInfo, showHiddenDisabled]);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  const onSelectionChanged = useCallback(() => {
    const selectedRows: Array<DeviceCopyDevice> | undefined = gridRef.current?.api.getSelectedRows();
    if (selectedRows && job) {
      //what has been de-selected in this call
      const newDirectlyDeselectedObjects = leftOuterJoin(
        job.objects,
        selectedRows,
        (l) => `${l.objectID}-${l.objectTypeID}`,
        (r) => `${r.obj_id}-${r.obj_type_id}`,
        (l, r) => {
          return { l: l, r: r };
        }
      )
        .filter((o) => null === o.r)
        .map((o) => o.l);

      //and what has been selected in this call
      const newDirectlySelectedObjects = leftOuterJoin(
        selectedRows,
        job.objects,
        (l) => `${l.obj_id}-${l.obj_type_id}`,
        (r) => `${r.objectID}-${r.objectTypeID}`,
        (l, r) => {
          return { l: l, r: r };
        }
      )
        .filter((o) => null === o.r)
        .map((o) => o.l);

      //if there are no new (de-)selections, do nothing
      if (newDirectlyDeselectedObjects.length > 0 || newDirectlySelectedObjects.length) {
        let newSelectedObjects = [...job.objects];

        //remove directly and indirectly deselected objects from the current selection
        if (newDirectlyDeselectedObjects.length > 0 && job.objects.length > 0) {
          const directlyDeselectedCopyObjects = newDirectlyDeselectedObjects.map((o) => {
            const objName =
              deviceList.find((d) => d.obj_type_id === o.objectTypeID && d.obj_id === o.objectID)?.obj_name ?? "";
            return {
              objectID: o.objectID,
              objectTypeID: o.objectTypeID,
              objectType: o.objectType,
              objectName: objName,
              objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
              selectLocked: false,
            } as SingleObjectCopy;
          });

          //start by selecting any devices needed by selected wells
          let allDeselectedObjects = innerJoin(
            directlyDeselectedCopyObjects.filter((o) => 54 === o.objectTypeID),
            wellLinks,
            (l) => l.objectID,
            (r) => r.well_id,
            (l, r) => {
              const objName = deviceList.find((d) => d.obj_type_id === 3 && d.obj_id === r.dev_id)?.obj_name ?? "";
              return {
                objectID: r.dev_id,
                objectTypeID: 3,
                objectType: "Device",
                objectName: objName,
                objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                selectLocked: true,
              } as SingleObjectCopy;
            }
          );

          //for tank stuff, start with de-selected devices and select any batteries to which they belong
          allDeselectedObjects = allDeselectedObjects.concat(
            innerJoin(
              directlyDeselectedCopyObjects.filter((o) => 3 === o.objectTypeID),
              batteryDevices,
              (l) => l.objectID,
              (r) => r.dev_id,
              (l, r) => {
                const objName = deviceList.find((d) => d.obj_type_id === 16 && d.obj_id === r.tnkb_id)?.obj_name ?? "";
                return {
                  objectID: r.tnkb_id,
                  objectTypeID: 16,
                  objectType: "Tank Battery",
                  objectName: objName,
                  objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                  selectLocked: true,
                } as SingleObjectCopy;
              }
            )
          );

          //then add in any batteries to which de-selected tanks belong
          //and start collecting everything into one list
          allDeselectedObjects = directlyDeselectedCopyObjects.concat(
            allDeselectedObjects.concat(
              innerJoin(
                directlyDeselectedCopyObjects.filter((o) => 17 === o.objectTypeID),
                batteryTanks,
                (l) => l.objectID,
                (r) => r.tank_id,
                (l, r) => {
                  const objName =
                    deviceList.find((d) => d.obj_type_id === 16 && d.obj_id === r.tnkb_id)?.obj_name ?? "";
                  return {
                    objectID: r.tnkb_id,
                    objectTypeID: 16,
                    objectType: "Tank Battery",
                    objectName: objName,
                    objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                    selectLocked: true,
                  } as SingleObjectCopy;
                }
              )
            )
          );

          //and any batteries which belong to selected tank pads
          allDeselectedObjects = allDeselectedObjects.concat(
            innerJoin(
              directlyDeselectedCopyObjects.filter((o) => 41 === o.objectTypeID),
              padBatteries,
              (l) => l.objectID,
              (r) => r.tnkp_id,
              (l, r) => {
                const objName = deviceList.find((d) => d.obj_type_id === 16 && d.obj_id === r.tnkb_id)?.obj_name ?? "";
                return {
                  objectID: r.tnkb_id,
                  objectTypeID: 16,
                  objectType: "Tank Battery",
                  objectName: objName,
                  objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                  selectLocked: true,
                } as SingleObjectCopy;
              }
            )
          );

          //next add in any tank pads to which the de-selected batteries belong
          allDeselectedObjects = allDeselectedObjects.concat(
            innerJoin(
              allDeselectedObjects.filter((o) => 16 === o.objectTypeID),
              padBatteries,
              (l) => l.objectID,
              (r) => r.tnkb_id,
              (l, r) => {
                const objName = deviceList.find((d) => d.obj_type_id === 41 && d.obj_id === r.tnkp_id)?.obj_name ?? "";
                return {
                  objectID: r.tnkp_id,
                  objectTypeID: 41,
                  objectType: "Tank Pad",
                  objectName: objName,
                  objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                  selectLocked: true,
                } as SingleObjectCopy;
              }
            )
          );

          //now turn it around and ensure that all devices and tanks for selected batteries are selected
          allDeselectedObjects = allDeselectedObjects.concat(
            innerJoin(
              allDeselectedObjects.filter((o) => 16 === o.objectTypeID),
              batteryDevices,
              (l) => l.objectID,
              (r) => r.tnkb_id,
              (l, r) => {
                const objName = deviceList.find((d) => d.obj_type_id === 3 && d.obj_id === r.dev_id)?.obj_name ?? "";
                return {
                  objectID: r.dev_id,
                  objectTypeID: 3,
                  objectType: "Device",
                  objectName: objName,
                  objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                  selectLocked: true,
                } as SingleObjectCopy;
              }
            )
          );

          allDeselectedObjects = allDeselectedObjects.concat(
            innerJoin(
              allDeselectedObjects.filter((o) => 16 === o.objectTypeID),
              batteryTanks,
              (l) => l.objectID,
              (r) => r.tnkb_id,
              (l, r) => {
                const objName = deviceList.find((d) => d.obj_type_id === 17 && d.obj_id === r.tank_id)?.obj_name ?? "";
                return {
                  objectID: r.tank_id,
                  objectTypeID: 17,
                  objectType: "Tank",
                  objectName: objName,
                  objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                  selectLocked: true,
                } as SingleObjectCopy;
              }
            )
          );

          //filter down to the distinct objects
          allDeselectedObjects = distinctBy(allDeselectedObjects, (o) => `${o.objectID}-${o.objectTypeID}`);

          //from the previously selected objects remove any deselected objects
          newSelectedObjects = newSelectedObjects.filter(
            (o) => !allDeselectedObjects.some((d) => d.objectID === o.objectID && d.objectTypeID === o.objectTypeID)
          );
        }

        //add directly and indirectly selected objects to the current selection
        if (newDirectlySelectedObjects.length > 0) {
          const directlySelectedCopyObjects = newDirectlySelectedObjects.map((o) => {
            const objName =
              deviceList.find((d) => d.obj_type_id === o.obj_type_id && d.obj_id === o.obj_id)?.obj_name ?? "";
            return {
              objectID: o.obj_id,
              objectTypeID: o.obj_type_id,
              objectType: o.obj_type,
              objectName: objName,
              objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
              selectLocked: false,
            } as SingleObjectCopy;
          });

          //start by selecting any devices needed by selected wells
          let linkedSelectedObjects = innerJoin(
            directlySelectedCopyObjects.filter((o) => 54 === o.objectTypeID),
            wellLinks,
            (l) => l.objectID,
            (r) => r.well_id,
            (l, r) => {
              const objName = deviceList.find((d) => d.obj_type_id === 3 && d.obj_id === r.dev_id)?.obj_name ?? "";
              return {
                objectID: r.dev_id,
                objectTypeID: 3,
                objectType: "Device",
                objectName: objName,
                objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                selectLocked: true,
              } as SingleObjectCopy;
            }
          );

          //for tank stuff, start with selected devices and select any batteries to which they belong
          linkedSelectedObjects = linkedSelectedObjects.concat(
            innerJoin(
              directlySelectedCopyObjects.filter((o) => 3 === o.objectTypeID),
              batteryDevices,
              (l) => l.objectID,
              (r) => r.dev_id,
              (l, r) => {
                const objName = deviceList.find((d) => d.obj_type_id === 16 && d.obj_id === r.tnkb_id)?.obj_name ?? "";
                return {
                  objectID: r.tnkb_id,
                  objectTypeID: 16,
                  objectType: "Tank Battery",
                  objectName: objName,
                  objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                  selectLocked: true,
                } as SingleObjectCopy;
              }
            )
          );

          //then add in any batteries to which selected tanks belong
          linkedSelectedObjects = linkedSelectedObjects.concat(
            innerJoin(
              directlySelectedCopyObjects.filter((o) => 17 === o.objectTypeID),
              batteryTanks,
              (l) => l.objectID,
              (r) => r.tank_id,
              (l, r) => {
                const objName = deviceList.find((d) => d.obj_type_id === 16 && d.obj_id === r.tnkb_id)?.obj_name ?? "";
                return {
                  objectID: r.tnkb_id,
                  objectTypeID: 16,
                  objectType: "Tank Battery",
                  objectName: objName,
                  objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                  selectLocked: true,
                } as SingleObjectCopy;
              }
            )
          );

          //and any batteries which belong to selected tank pads
          linkedSelectedObjects = linkedSelectedObjects.concat(
            innerJoin(
              directlySelectedCopyObjects.filter((o) => 41 === o.objectTypeID),
              padBatteries,
              (l) => l.objectID,
              (r) => r.tnkp_id,
              (l, r) => {
                const objName = deviceList.find((d) => d.obj_type_id === 16 && d.obj_id === r.tnkb_id)?.obj_name ?? "";
                return {
                  objectID: r.tnkb_id,
                  objectTypeID: 16,
                  objectType: "Tank Battery",
                  objectName: objName,
                  objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                  selectLocked: true,
                } as SingleObjectCopy;
              }
            )
          );

          //next add in any tank pads to which the selected batteries belong
          linkedSelectedObjects = linkedSelectedObjects.concat(
            innerJoin(
              linkedSelectedObjects.concat(directlySelectedCopyObjects).filter((o) => 16 === o.objectTypeID),
              padBatteries,
              (l) => l.objectID,
              (r) => r.tnkb_id,
              (l, r) => {
                const objName = deviceList.find((d) => d.obj_type_id === 41 && d.obj_id === r.tnkp_id)?.obj_name ?? "";
                return {
                  objectID: r.tnkp_id,
                  objectTypeID: 41,
                  objectType: "Tank Pad",
                  objectName: objName,
                  objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                  selectLocked: true,
                } as SingleObjectCopy;
              }
            )
          );

          //now turn it around and ensure that all devices and tanks for selected batteries are selected
          linkedSelectedObjects = linkedSelectedObjects.concat(
            innerJoin(
              linkedSelectedObjects.concat(directlySelectedCopyObjects).filter((o) => 16 === o.objectTypeID),
              batteryDevices,
              (l) => l.objectID,
              (r) => r.tnkb_id,
              (l, r) => {
                const objName = deviceList.find((d) => d.obj_type_id === 3 && d.obj_id === r.dev_id)?.obj_name ?? "";
                return {
                  objectID: r.dev_id,
                  objectTypeID: 3,
                  objectType: "Device",
                  objectName: objName,
                  objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                  selectLocked: true,
                } as SingleObjectCopy;
              }
            )
          );

          linkedSelectedObjects = linkedSelectedObjects.concat(
            innerJoin(
              linkedSelectedObjects.concat(directlySelectedCopyObjects).filter((o) => 16 === o.objectTypeID),
              batteryTanks,
              (l) => l.objectID,
              (r) => r.tnkb_id,
              (l, r) => {
                const objName = deviceList.find((d) => d.obj_type_id === 17 && d.obj_id === r.tank_id)?.obj_name ?? "";
                return {
                  objectID: r.tank_id,
                  objectTypeID: 17,
                  objectType: "Tank",
                  objectName: objName,
                  objectCopyStatus: SingleObjectCopyStatusEnum.NotStarted,
                  selectLocked: true,
                } as SingleObjectCopy;
              }
            )
          );

          //filter down to the distinct objects
          linkedSelectedObjects = distinctBy(linkedSelectedObjects, (o) => `${o.objectID}-${o.objectTypeID}`);

          //objects now selected due to linkage to directly selected objects
          //may already be selected,
          //if so, we need to change them to have their selection locked
          newSelectedObjects = leftOuterJoin(
            newSelectedObjects,
            linkedSelectedObjects,
            (l) => `${l.objectID}-${l.objectTypeID}`,
            (r) => `${r.objectID}-${r.objectTypeID}`,
            (l, r) => {
              //if there is a right object, lock the selection of the left object
              if (null !== r) {
                return { ...l, selectLocked: true };
              } else {
                return l;
              }
            }
          );

          //now that we have dealt with setting *previously* selected items
          //to "locked", if appropriate, add in the items directly selected
          //in this call
          newSelectedObjects = newSelectedObjects.concat(directlySelectedCopyObjects);

          //any objects selected due to linkage to directly selected objects
          //that are not already selected need to be added to the list
          //without creating any duplicates
          const newLinkedSelectedObjects = leftOuterJoin(
            linkedSelectedObjects,
            newSelectedObjects,
            (l) => `${l.objectID}-${l.objectTypeID}`,
            (r) => `${r.objectID}-${r.objectTypeID}`,
            (l, r) => {
              return { l: l, r: r };
            }
          )
            .filter((o) => null === o.r)
            .map((o) => o.l);

          //now we can concatenate the linked selected objects that were not already in the list
          newSelectedObjects = newSelectedObjects.concat(newLinkedSelectedObjects);
        }

        dispatchJob({
          type: CopyDeviceJobActionTypeEnum.SetDevices,
          devices: newSelectedObjects,
        });

        //even though we should be able to work on only the changes from the previous selection
        //for now, let's take the slow but safe route of just setting everything the way it should be
        //that way we are sure that everything is in sync
        gridRef.current?.api.forEachNode((node) => {
          const sel = newSelectedObjects.some(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (o) => o.objectID === node.data!.obj_id && o.objectTypeID === node.data!.obj_type_id
          );

          node.setSelected(sel);
        });

        setNextButtonDisabled(selectedRows.length === 0);
      }
    }
  }, [deviceList, wellLinks, batteryDevices, batteryTanks, padBatteries, job.objects]);

  const onRowDataUpdated = useCallback(
    (event: RowDataUpdatedEvent<DeviceCopyDevice>) => {
      if (event != null && event.api && job.objects) {
        const selectedObjects = job.objects.map((o) => {
          return { objectID: o.objectID, objectTypeID: o.objectTypeID };
        });
        event.api.forEachNode((node) => {
          if (node.data) {
            const sel = selectedObjects.some(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              (o) => o.objectID === node.data!.obj_id && o.objectTypeID === node.data!.obj_type_id
            );
            node.setSelected(sel);
          }
        });
      }
    },
    [job.objects]
  );

  const shouldDisplayEnabled = useCallback((device: DeviceCopyDevice) => {
    return !device.obj_enabled;
  }, []);

  const shouldDisplayVisible = useCallback((device: DeviceCopyDevice) => {
    return !device.obj_visible;
  }, []);

  //setup all the columns
  const columnDefs = useMemo<Array<ColDef>>(() => {
    let columns = [
      {
        headerName: "Selected",
        colId: "selected",
        checkboxSelection: ({ data }: CheckboxSelectionCallbackParams<DeviceCopyDevice>) => {
          return (
            !!data &&
            !(
              job?.objects.some(
                (o) => o.objectID === data.obj_id && o.objectTypeID === data.obj_type_id && o.selectLocked === true
              ) ?? false
            )
          );
        },
        showDisabledCheckboxes: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        minWidth: 100,
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if (nodeA.isSelected() === nodeB.isSelected()) {
            if (valueA == valueB) {
              return 0;
            }
            return valueA > valueB ? 1 : -1;
          } else {
            return nodeA.isSelected() ? 1 : -1;
          }
        },
      },
      {
        headerName: "Object Name",
        field: "obj_name",
        //sort: "asc",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Object Type",
        field: "obj_type",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith", "equals"],
          buttons: ["reset", "apply"],
          debounceMs: 300,
          maxNumConditions: 6,
        },
      },
      {
        headerName: "Object ID",
        field: "obj_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Object GUID",
        field: "obj_guid",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Object External ID",
        field: "obj_external_id",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
    ] as Array<ColDef>;

    if (showHiddenDisabled) {
      columns = columns.concat([
        {
          headerName: "Enabled",
          field: "obj_enabled",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains", "startsWith"],
            debounceMs: 300,
            suppressAndOrCondition: true,
          },
          cellRenderer: ObjectEnabledCellRenderer,
          cellRendererParams: {
            shouldDisplay: shouldDisplayEnabled,
          } as ObjectEnabledRendererProps,
        },
        {
          headerName: "Visible",
          field: "obj_visible",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains", "startsWith"],
            debounceMs: 300,
            suppressAndOrCondition: true,
          },
          cellRenderer: ObjectVisibleCellRenderer,
          cellRendererParams: {
            shouldDisplay: shouldDisplayVisible,
          } as ObjectVisibleRendererProps,
        },
      ]);
    }
    return columns;
  }, [showHiddenDisabled, job?.objects]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={deviceList}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            rowSelection={{
              mode: "multiRow",
              enableSelectionWithoutKeys: true,
            }}
            onSelectionChanged={onSelectionChanged}
            onFirstDataRendered={autoSizeColumns}
            onRowDataUpdated={onRowDataUpdated}
            suppressClickEdit={true}
            suppressCellFocus={true}
            alwaysMultiSort={true}
          />
        </div>
      </Box>
      <SideSheet title="Options" open={true}>
        <CopyOptions job={job} dispatchJob={dispatchJob} />
        <>
          <Typography variant="h6" sx={{ marginLeft: "-11px", marginTop: "10px" }}>
            {"View Options"}
          </Typography>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={showHiddenDisabled}
                onChange={(_, checked) => setShowHiddenDisabled(checked)}
              />
            }
            label="View Disabled and Hidden Devices"
            labelPlacement="start"
          />
        </>
      </SideSheet>
    </>
  );
}
