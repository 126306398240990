import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, CircularProgress, Divider, Fab, useTheme, Stack, Autocomplete, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { useUserRole } from "../../contexts/user-role-context/UserRoleContext";
import { useAlert } from "react-alert";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import { useConfirm } from "../../contexts/confirm-context/ConfirmContext";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import ContentSection from "../../components/content-section/ContentSection";
import { AutocompleteOption } from "../../components/form-components/FormInputAutocomplete";
import { useCustomerList } from "../../queries/customer/UseCustomerList";
import MuiIconCellRenderer, {
  MuiIconRendererProps,
} from "../../components/ag-grid-extensions/renderers/MuiIconCellRenderer";
import { CidrAddressModel } from "../../queries/cidr-address/Models";
import EditCidrAddress from "./EditCidrAddress";
import { useCidrAddressList } from "../../queries/cidr-address/UseCidrAddressList";
import { useSaveCidrAddress } from "../../queries/cidr-address/UseSaveCidrAddress";
import { useDeleteCidrAddress } from "../../queries/cidr-address/UseDeleteCidrAddress";

export default function CidrAddress() {
  const theme = useTheme();
  const confirm = useConfirm();
  const alert = useAlert();
  const { displayToast } = useToastNotification();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const { isOpsCenterWriterMember } = useUserRole();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [customerList, setCustomerList] = useState<Array<AutocompleteOption>>([]);
  const { data: newCustomerList, isSuccess: isCustomerSuccess } = useCustomerList();
  const [customerID, setCustomerID] = useState<number | undefined>();
  const [cidrAddressList, setCidrAddressList] = useState<Array<CidrAddressModel>>([]);
  const editingAddressRef = useRef<CidrAddressModel | null>(null);
  const { data: newAddresses, isSuccess, isFetching, refetch } = useCidrAddressList({enabled: (customerID ?? 0) > 0 , customerId: customerID ?? 0 });
  const {
    mutate: saveCidrAddress,
   } = useSaveCidrAddress({ customerId: customerID ?? 0 });
  const {
    mutate: deleteCidrAddress,
  } = useDeleteCidrAddress({ customerId: customerID ?? 0 });

  useEffect(() => {
    setCidrAddressList([]);
  }, [customerID]);

  useEffect(() => {
    if (isCustomerSuccess === true && newCustomerList && newCustomerList.length > 0) {
      setCustomerList(
        newCustomerList
          .filter((c) => c.customerEnabled === true)
          .map((c) => {
            return { id: c.customerID, label: `${c.customerID} - ${c.customerName}` };
          })
      );
    }
  }, [isCustomerSuccess, newCustomerList]);

  //if we get a new set of addresses, put it in our state
  useEffect(() => {
    if (newAddresses) {
      setCidrAddressList(newAddresses);
    }
  }, [isSuccess, newAddresses]);

  const onSaveCidrAddress = useCallback((cidrAddress: CidrAddressModel) => {
    saveCidrAddress(cidrAddress, {
      onSuccess: () =>
        displayToast({
          message: `Saved ${cidrAddress.address} successfully`,
          severity: "success",
        }),
          onError: (error) => {
          if (error.response?.status == 400) {
            alert.error(`Error saving address ${cidrAddress.address}: Invalid IP address`);
          } else {
            alert.error(`Error saving address ${cidrAddress.address}: ${error}`);
          }
        }
      });
    }, []);

  const onDeleteClicked = useCallback((cidrAddress: CidrAddressModel) => {
    //confirm rejects the promise if the user does not confirm
    confirm({
      title: "Confirm Delete CIDR Address",
      description: `Are you sure you want to delete address ${cidrAddress.address}?`,
      allowClose: false,
    })
      .then(() => {
        deleteCidrAddress(cidrAddress, {
          onSuccess: () =>
            displayToast({
              message: `Deleted ${cidrAddress.address} successfully`,
              severity: "success",
            }),
            onError: (error) => {
              alert.error(`Error deleting address ${cidrAddress.address}: ${error}`);
            }
          });
      })
      .catch(() => {
        console.log(`Delete config item ${cidrAddress.address} canceled!`);
      });
  }, []);

  const onEditClicked = useCallback((cidrAddress: CidrAddressModel) => {
    editingAddressRef.current = cidrAddress;
    setEditDialogOpen(true);
  }, []);

  const handleEditDialogClose = useCallback(() => {
    setEditDialogOpen(false);
  }, []);

  //setup all the columns
  const columnDefs = useMemo<Array<ColDef>>(() => {
    const columns = [
      {
        headerName: "Delete",
        colId: "delete",
        width: 100,
        cellRenderer: MuiIconCellRenderer,
        cellRendererParams: {
          disabled: !isOpsCenterWriterMember,
          iconName: "delete",
          size: "medium",
          tooltipText: "Delete",
          shouldDisplay: isOpsCenterWriterMember,
          onClick: onDeleteClicked,
        } as MuiIconRendererProps,
      },
      {
        headerName: "Address",
        field: "address",
        minWidth: 300,
        flex: 2,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Edit",
        colId: "edit",
        width: 100,
        cellRenderer: MuiIconCellRenderer,
        cellRendererParams: {
          disabled: false,
          iconName: "edit",
          size: "medium",
          tooltipText: "Edit",
          shouldDisplay: isOpsCenterWriterMember,
          onClick: onEditClicked,
        } as MuiIconRendererProps,
      },
    ] as Array<ColDef>;

    return columns;
  }, [isOpsCenterWriterMember]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  function openDialogForNewAddress(): void {
    //wipe out the current editing address
    editingAddressRef.current = null;

    //then open the dialog
    setEditDialogOpen(true);
  }

  return (
    <>
      <ContentView>
        <ContentHeader title={"CIDR Addresses"} />
        <Stack
          direction="row"
          spacing={3}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{
            backgroundColor: theme.palette.neutral.lowContrast,
            flexWrap: "wrap",
            padding: "6px",
            rowGap: "6px",
            alignItems: "center",
          }}
        >
          <Autocomplete
            autoHighlight
            size="small"
            options={customerList}
            onChange={(event, value) => {
              setCustomerID(value?.id);
            }}
            sx={{ width: "42ch" }}
            renderInput={(params) => <TextField {...params} label="Customer" />}
          />
          <Box
            sx={{
              "& > button": { m: 1 },
              display: "flex",
              width: "14ch",
              marginLeft: "auto !important",
              marginRight: "1em !important",
            }}
          >
            <Button
              onClick={() => refetch()}
              endIcon={isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
              variant="contained"
            >
              Refresh
            </Button>
          </Box>
        </Stack>
        <ContentSection>
          {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              "& .ag-theme-alpine .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
              "& .ag-theme-alpine-dark .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          >
            <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                defaultColDef={defaultColDef}
                rowData={cidrAddressList}
                columnDefs={columnDefs}
                ensureDomOrder={true}
                enableCellTextSelection={true}
                suppressClickEdit={true}
                suppressCellFocus={true}
                alwaysMultiSort={true}
                scrollbarWidth={0}
                columnMenu="legacy"
              />
            </div>
          </Box>
        </ContentSection>
      </ContentView>

      {/* Add/edit CIDR address */}
      <EditCidrAddress
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        saveCidrAddress={onSaveCidrAddress}
        srcModel={editingAddressRef.current}
      />

      {/* Floating Action Button for adding a new config item */}
      <Fab
        color="primary"
        aria-label="add"
        title="Add new CIDR address"
        onClick={() => openDialogForNewAddress()}
        disabled={!isOpsCenterWriterMember}
      >
        <AddIcon />
      </Fab>
    </>
  );
}
