import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { CidrAddressModel, CidrAddressQueryProperties } from "./Models";

//custom query hook
export function useCidrAddressList({ enabled, customerId }: CidrAddressQueryProperties) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied query properties
  const queryKey = keys.cidrAddressQuery(customerId);

  return useQuery<Array<CidrAddressModel>, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<Array<CidrAddressModel>>(`cidraddress/${customerId}`);
      return data;
    },
    {
      enabled: enabled,
      staleTime: 2 * 60 * 1000, //we will use data that is slightly stale because the CIDR addresses don't change too often
      refetchInterval: 60 * 1000, //but let's auto-refresh the data
    }
  );
}
