import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { CidrAddressModel, CidrAddressQueryProperties } from "./Models";

export function useDeleteCidrAddress({ customerId }: CidrAddressQueryProperties) {
  const { axios: ax } = useAxios();
  const queryClient = useQueryClient();

  return useMutation<Array<CidrAddressModel>, Error, CidrAddressModel>(
    async (cidrAddress) => {
      const { data } = await ax.delete<Array<CidrAddressModel>>(`cidraddress/${customerId}/${cidrAddress.id}`);
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(keys.cidrAddressQuery(customerId), data);
      },
    }
  );
}
