import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { CidrAddressModel, CidrAddressQueryProperties } from "./Models";
import { AxiosError } from "axios";

export function useSaveCidrAddress({ customerId }: CidrAddressQueryProperties) {
  const { axios: ax } = useAxios();
  const queryClient = useQueryClient();

  return useMutation<Array<CidrAddressModel>, AxiosError, CidrAddressModel>(
    async (cidrAddress) => {
      const { data } = await ax.put<Array<CidrAddressModel>>(
        `cidraddress/${customerId}`,
        cidrAddress
      );
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(keys.cidrAddressQuery(customerId), data);
      },
    }
  );
}
