import React, { forwardRef, PropsWithChildren } from "react";
import { AgGridEvent } from "ag-grid-community";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";

export type AutoWrapHeaderProps = (AgGridReactProps) & PropsWithChildren<unknown>;

const template =
`
<div class="ag-cell-label-container" role="presentation">
    <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span data-ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span data-ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span data-ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span data-ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
        <span data-ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;word-break:normal;"></span>
        <span data-ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
    </div>
</div>
`;

// eslint-disable-next-line react/prop-types
const AutoWrapHeaderGrid = forwardRef<AgGridReact, AutoWrapHeaderProps>(({ defaultColDef, ...props }, ref) => {
  const headerHeightSetter = (event: AgGridEvent) => {
    let headerHeight = 0;

    if (document.querySelectorAll(".ag-header-row-column-group").length) {
      headerHeight = groupHeaderHeightGetter();
    }

    const padding = 20;
    const height = headerHeightGetter() + padding;
    event.api.setGridOption("headerHeight", height);

    if (headerHeight) {
      event.api.setGridOption("groupHeaderHeight", headerHeight);
    }

    event.api.resetRowHeights();
  };

  const headerHeightGetter = () => {
    const columnHeaderTexts = [...document.querySelectorAll(".ag-header-cell-text")];
    const clientHeights = columnHeaderTexts.map((headerText) => headerText.clientHeight);
    const tallestHeaderTextHeight = Math.max(...clientHeights);

    return tallestHeaderTextHeight;
  };

  const groupHeaderHeightGetter = () => {
    const columnHeaderTexts = [...document.querySelectorAll(".ag-header-row-column-group .ag-header-group-cell")];
    const clientHeights = columnHeaderTexts.map((headerText) => headerText.clientHeight);
    const tallestHeaderTextHeight = Math.max(...clientHeights);

    return tallestHeaderTextHeight;
  };

  return (
    <AgGridReact
      ref={ref}
      {...props}
      defaultColDef={{
        ...defaultColDef,
        headerComponentParams: {
          template: template,
        },
        minWidth: 100,
      }}
      onFirstDataRendered={headerHeightSetter}
      onColumnResized={headerHeightSetter}
      columnMenu="legacy"
    />
  );
});

AutoWrapHeaderGrid.displayName = "AutoWrapHeaderGrid";
export default AutoWrapHeaderGrid;
