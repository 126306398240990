import React, { useEffect, useRef, useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { isMobileSafari } from "react-device-detect";
import "./App.css";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { Paper, createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { loginRequest } from "../services/msal-service/MSALService";
import HasTokenTemplate from "../components/has-token/HasTokenTemplate";
import DefaultLayout from "../layouts/default-layout/DefaultLayout";
import { ConfirmProvider } from "../contexts/confirm-context/ConfirmContext";
import { UserRoleProvider } from "../contexts/user-role-context/UserRoleContext";
import { useAppSettings } from "../contexts/app-context/AppContext";
import { ToastNotificationProvider } from "../contexts/toast-notification/ToastNotificationContext";
import { AllCommunityModule, ModuleRegistry, provideGlobalGridOptions } from "ag-grid-community";

//Alert Provider
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "../components/alert-template/AlertTemplate";

const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  //timeout: 5000,
  //offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

export default function App() {
  const renderCount = useRef(0);
  const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);
  const [appSettings] = useAppSettings();

  useEffect(() => {
    // Register all community features
    ModuleRegistry.registerModules([AllCommunityModule]);
    
    // Mark all grids as using legacy themes
    provideGlobalGridOptions({ theme: "legacy"});
  }, []);

  const theme = useMemo(() => {
    return createTheme(appSettings.themeSet[appSettings.themeSet.mode].main);
  }, [appSettings.themeSet]);

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, loginRequest);
    }
  }, [error]);

  useEffect(() => {
    console.log(`App rendered ${renderCount.current} times`);
    renderCount.current += 1;
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <ToastNotificationProvider>
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <ConfirmProvider
              defaultOptions={{
                confirmationText: "Okay",
                confirmationButtonProps: { color: "secondary" },
                cancellationButtonProps: { color: "primary" },
                allowClose: true,
              }}
            >
              <Router>
                {/* The outer Paper ensures that the theme's background color fills iOS devices displays.
                The inner Paper ensures that content clears all iOS stuff */}
                <Paper
                  sx={{
                    height: isMobileSafari ? "100dvh" : "100vh",
                  }}
                >
                  <Paper
                    sx={{
                      height: isMobileSafari ? "-webkit-fill-available" : "100vh",
                      padding:
                        "env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)",
                    }}
                  >
                    <AuthenticatedTemplate>
                      <HasTokenTemplate>
                        <UserRoleProvider>
                          <DefaultLayout />
                        </UserRoleProvider>
                      </HasTokenTemplate>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                      <p>No users are signed in!</p>
                    </UnauthenticatedTemplate>
                  </Paper>
                </Paper>
              </Router>
            </ConfirmProvider>
          </AlertProvider>
        </ToastNotificationProvider>{" "}
      </ThemeProvider>
    </LocalizationProvider>
  );
}
